import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { PaginationComponent } from './pagination.component';
import { FieldModule } from '../field/field.module';


@NgModule({
  declarations: [PaginationComponent],
  exports: [PaginationComponent],
  imports: [
    FieldModule,
    CommonModule,
    ReactiveFormsModule
  ],
})
export class PaginationModule { }

<div>
  <svg width="200" height="30" viewBox="0 0 200 30" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!signet">
      <path d="M45 8.625H48.525L48.7 10.275C49.25 9.225 50.375 8.25 52.075 8.25C54.025 8.25 55.05 9.35 55.25 10.925V12.725H52.375C52.375 12.725 52.2 11.5 50.725 11.5C49.375 11.5 48.775 12.65 48.775 14.2V21.25H45V8.625Z" fill="#082F3A"/>
      <path d="M56.125 14.975C56.125 11.275 58.525 8.25 62.775 8.25C67.025 8.25 68.9 10.825 68.9 14.2C68.9 15.075 68.725 15.8 68.725 15.8H59.925C60.15 17.5 61.15 18.6 63 18.6C65.25 18.6 65.7 17.45 65.7 17.45H68.65V19.125C68.65 19.125 67.425 21.625 62.925 21.625C58.425 21.625 56.125 18.725 56.125 14.975ZM65.275 13.525C65.275 12.325 64.45 11.25 62.75 11.25C61.05 11.25 60.3 12.2 60 13.525H65.275Z" fill="#082F3A"/>
      <path d="M70.775 19.125V17.425H73.6C73.7 17.8 74.55 18.875 76.3 18.875C77.75 18.875 78.25 18.375 78.25 17.675C78.25 16.875 77.525 16.575 76.325 16.375L74.725 16.175C72.45 15.875 70.95 14.75 70.95 12.4C70.95 10.05 72.9 8.25 76.1 8.25C79 8.25 80.525 9.35 81.075 10.65V12.35H78.225C78.175 12.075 77.575 11 76.025 11C74.825 11 74.325 11.5 74.325 12.2C74.325 12.9 74.9 13.25 75.925 13.425L77.525 13.65C79.95 14 81.625 14.925 81.625 17.35C81.625 19.775 79.65 21.625 76.25 21.625C72.85 21.625 71.075 19.95 70.775 19.125Z" fill="#082F3A"/>
      <path d="M84.1 8.625H87.625L87.8 10.425C88.625 9.1 90.15 8.25 92 8.25C95.575 8.25 97.625 11.225 97.625 14.9C97.625 18.575 95.45 21.625 92 21.625C90.175 21.625 88.75 20.85 87.875 19.55V27.5H84.1V8.625ZM93.85 14.9C93.85 13.075 92.825 11.65 90.85 11.65C89.05 11.65 87.875 13.05 87.875 14.9C87.875 16.75 89.05 18.225 90.85 18.225C92.825 18.225 93.85 16.775 93.85 14.9Z" fill="#082F3A"/>
      <path d="M99.4583 14.9C99.4583 11.225 101.933 8.25 106.133 8.25C110.333 8.25 112.808 11.225 112.808 14.9C112.808 18.575 110.308 21.625 106.133 21.625C101.958 21.625 99.4583 18.6 99.4583 14.9ZM109.033 14.9C109.033 13.05 108.108 11.525 106.133 11.525C104.158 11.525 103.233 13.05 103.233 14.9C103.233 16.75 104.158 18.35 106.133 18.35C108.108 18.35 109.033 16.8 109.033 14.9Z" fill="#082F3A"/>
      <path d="M115.308 8.625H118.833L119.008 10.3C119.558 9.35 120.808 8.25 122.783 8.25C126.133 8.25 127.308 10.65 127.308 13.4V21.25H123.558V14.1C123.558 12.625 122.983 11.625 121.483 11.625C119.658 11.625 119.083 12.925 119.083 14.15V21.25H115.308V8.625Z" fill="#082F3A"/>
      <path d="M129.758 19.125V17.425H132.583C132.683 17.8 133.533 18.875 135.283 18.875C136.733 18.875 137.233 18.375 137.233 17.675C137.233 16.875 136.508 16.575 135.308 16.375L133.708 16.175C131.433 15.875 129.933 14.75 129.933 12.4C129.933 10.05 131.883 8.25 135.083 8.25C137.983 8.25 139.508 9.35 140.058 10.65V12.35H137.208C137.158 12.075 136.558 11 135.008 11C133.808 11 133.308 11.5 133.308 12.2C133.308 12.9 133.883 13.25 134.908 13.425L136.508 13.65C138.933 14 140.608 14.925 140.608 17.35C140.608 19.775 138.633 21.625 135.233 21.625C131.833 21.625 130.058 19.95 129.758 19.125Z" fill="#082F3A"/>
      <path d="M142.983 2.75H146.883V6.25H142.983V2.75ZM143.083 8.625H146.833V21.25H143.083V8.625Z" fill="#082F3A"/>
      <path d="M153.758 19.45L153.558 21.25H150.033V2.5H153.808V10.325C154.658 9.025 156.083 8.25 157.933 8.25C161.433 8.25 163.558 11.175 163.558 14.825C163.558 18.475 161.483 21.625 157.883 21.625C156.008 21.625 154.583 20.775 153.758 19.45ZM159.783 14.95C159.783 13.1 158.733 11.65 156.758 11.65C154.983 11.65 153.808 13.075 153.808 14.925C153.808 16.775 154.983 18.225 156.783 18.225C158.733 18.225 159.783 16.775 159.783 14.95Z" fill="#082F3A"/>
      <path d="M166.083 2.5H169.858V21.25H166.083V2.5Z" fill="#082F3A"/>
      <path d="M172.367 14.975C172.367 11.275 174.767 8.25 179.017 8.25C183.267 8.25 185.142 10.825 185.142 14.2C185.142 15.075 184.967 15.8 184.967 15.8H176.167C176.392 17.5 177.392 18.6 179.242 18.6C181.492 18.6 181.942 17.45 181.942 17.45H184.892V19.125C184.892 19.125 183.667 21.625 179.167 21.625C174.667 21.625 172.367 18.725 172.367 14.975ZM181.517 13.525C181.517 12.325 180.692 11.25 178.992 11.25C177.292 11.25 176.542 12.2 176.242 13.525H181.517Z" fill="#082F3A"/>
      <path d="M186.942 14.975C186.942 11.275 189.342 8.25 193.592 8.25C197.842 8.25 199.717 10.825 199.717 14.2C199.717 15.075 199.542 15.8 199.542 15.8H190.742C190.967 17.5 191.967 18.6 193.817 18.6C196.067 18.6 196.517 17.45 196.517 17.45H199.467V19.125C199.467 19.125 198.242 21.625 193.742 21.625C189.242 21.625 186.942 18.725 186.942 14.975ZM196.092 13.525C196.092 12.325 195.267 11.25 193.567 11.25C191.867 11.25 191.117 12.2 190.817 13.525H196.092Z" fill="#082F3A"/>
      <path d="M18.0746 0C12.6246 0 7.63289 3.08333 5.19956 7.95833L1.20789 15.9417C0.116224 18.1167 -0.317109 20.6083 0.241224 22.975C0.93289 25.8917 2.82456 28.275 5.46622 29.5917L5.91622 29.8167C6.75789 30.2333 7.77456 29.9 8.19956 29.0583L16.6662 12.125H26.1746C31.6495 12.125 36.0912 7.68333 36.0912 2.20833V1.7C36.0912 0.758333 35.3329 0 34.3912 0H18.0746V0Z" fill="#00D17C"/>
      <path d="M34.3916 0H18.075C12.625 0 7.6333 3.08333 5.19997 7.95833L3.11664 12.125H26.175C31.65 12.125 36.0916 7.68333 36.0916 2.20833V1.7C36.0916 0.758333 35.3333 0 34.3916 0V0Z" fill="#00DD78"/>
      <path d="M3.11665 12.125L1.20832 15.95C0.116651 18.1167 -0.316682 20.6083 0.241651 22.975C0.933318 25.8917 2.82498 28.275 5.46665 29.5917L5.91665 29.8167C6.75832 30.2333 7.77499 29.9 8.19999 29.0583L16.6667 12.125" fill="#00CC74"/>
  </svg>
  <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="signet">
    <path d="M18.0746 0C12.6246 0 7.63289 3.08333 5.19956 7.95833L1.20789 15.9417C0.116224 18.1167 -0.317109 20.6083 0.241224 22.975C0.93289 25.8917 2.82456 28.275 5.46622 29.5917L5.91622 29.8167C6.75789 30.2333 7.77456 29.9 8.19956 29.0583L16.6662 12.125H26.1746C31.6495 12.125 36.0912 7.68333 36.0912 2.20833V1.7C36.0912 0.758333 35.3329 0 34.3912 0H18.0746V0Z" fill="#00D17C"/>
    <path d="M34.3916 0H18.075C12.625 0 7.6333 3.08333 5.19997 7.95833L3.11664 12.125H26.175C31.65 12.125 36.0916 7.68333 36.0916 2.20833V1.7C36.0916 0.758333 35.3333 0 34.3916 0V0Z" fill="#00DD78"/>
    <path d="M3.11665 12.125L1.20832 15.95C0.116651 18.1167 -0.316682 20.6083 0.241651 22.975C0.933318 25.8917 2.82498 28.275 5.46665 29.5917L5.91665 29.8167C6.75832 30.2333 7.77499 29.9 8.19999 29.0583L16.6667 12.125" fill="#00CC74"/>
  </svg>
</div>

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private toastrService: ToastrService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          let errorMsg: string = '';
          switch (response.status) {
            case 400: {
              errorMsg = $localize`Wystąpił problem z zapisem formularza. Zmień dane i spróbuj ponownie.`;
              if (response?.error[0]?.includes('Given organization units have relations')) {
                errorMsg = $localize`Niestety nie można odpiąć lokalizacji/oddziału oraz roku ponieważ źródło emisji jest przypisane w emisjach dla wskazanych parametrów.`;
              } else if (response?.error[0]?.includes('Unable to delete emission_source due to its relations to existing emissions')) {
                errorMsg = $localize`Niestety nie można usunąć źródła emisji ponieważ jest przypisane do emisji.`;
              }
              if (response?.error?.company?.includes('User is not a member of company')) {
                errorMsg = null;
              }
            }
          }
          if (errorMsg) {
            this.toastrService.error(errorMsg, $localize`Błąd`).toastId;
          }
          return throwError(() => response);
        })
      );
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class AppInterceptorService implements HttpInterceptor {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({headers: request.headers.set('Accept-Language', this.locale)});

    return next.handle(request);
  }
}

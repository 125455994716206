import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FieldCheckboxComponent } from './field-checkbox.component';

@NgModule({
  declarations: [FieldCheckboxComponent],
  exports: [FieldCheckboxComponent],
  imports: [
    CommonModule,
  ],
})
export class FieldCheckboxModule { }

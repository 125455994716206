import { Component, Inject, LOCALE_ID } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) {}

  public ngOnInit(): void {
    const currentLocale: string = localStorage.getItem('lang');
    const path: string = window.location.pathname;

    if (this.locale === 'pl' && currentLocale === 'en') {
      location.href = '/en';
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';

import { ContextMenuRef } from '../context-menu-ref';
import { CONTEXT_MENU_DATA } from '../context-menu-tokens';

@Component({
  selector: 'lib-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit {
  constructor(
    @Inject(CONTEXT_MENU_DATA) public data: any,
    public contextMenuRef: ContextMenuRef
  ) { }

  public ngOnInit(): void {
  }

  public close(): void {
    this.contextMenuRef.close();
  }

}

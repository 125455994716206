import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpHeaders,
  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthService, RefreshAccessModelDomainGet } from '../../../projects/responsiblee/src/app/auth/auth.service';
import { AuthActions } from '../../../projects/responsiblee/src/app/auth/store/auth.actions';

export const INTERCEPTOR_SKIP_HEADER: string = 'X-Skip-Interceptor';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private store: Store,
    private router: Router
  ) { }

  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has(INTERCEPTOR_SKIP_HEADER)) {
      const headers: HttpHeaders = request.headers.delete(INTERCEPTOR_SKIP_HEADER);
      return next.handle(request.clone({ headers }));
    }

    if (JwtInterceptor.isLoginUrl(request.url)) {
      return next.handle(request);
    }

    const jwt: string | null = sessionStorage.getItem('access');
    if (jwt) {
      request = JwtInterceptor.addToken(request, jwt);
    }

    // @ts-ignore
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 0)) {
        if (!request.url.endsWith('/user/login/refresh/')) {
          return this.handle401Error(request, next);
        }
        this.store.dispatch([new AuthActions.Logout()]).subscribe(() => {
          this.router.navigate(['/logowanie']).then();
        });
      } else {
        return throwError(error);
      }
    }));
  }

  private static isLoginUrl(url: string): boolean {
    const expression: RegExp = new RegExp('\/login/$');
    return expression.test(url);
  }

  private static addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone(request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const access: string | null = sessionStorage.getItem('access');
      const refresh: string | null = sessionStorage.getItem('refresh');

      return this.authService.refreshAccess({access, refresh}).pipe(
        switchMap((token: RefreshAccessModelDomainGet) => {
          this.isRefreshing = false;
          sessionStorage.setItem('access', token.access);
          this.refreshTokenSubject.next(token.access);
          return next.handle(JwtInterceptor.addToken(request, token.access));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap((jwt: string) => next.handle(JwtInterceptor.addToken(request, jwt))));
    }
  }
}

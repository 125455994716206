import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FilterComponent } from './filter/filter.component';
import { FilterTabComponent } from './filter-tab/filter-tab.component';

@NgModule({
  declarations: [FilterComponent, FilterTabComponent],
  exports: [FilterComponent, FilterTabComponent],
  imports: [
    CommonModule,
  ],
})
export class FilterModule { }

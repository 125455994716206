import { Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  public transform(
    value: any,
    search: string,
    propertyIndex: number = 0
  ): any {
    if (value && value.length) {
      const regexp: any = new RegExp(search, 'i');
      const properties: any = Object.keys(value[0]);
      return [
        ...value.filter((item: any) => regexp.test(item[properties[propertyIndex]])),
      ];
    }
  }
}

@UntilDestroy()
@Component({
  selector: 'lib-field-select',
  templateUrl: './field-select.component.html',
  styleUrls: ['./field-select.component.scss'],
})
export class FieldSelectComponent implements OnInit {
  @Input()
  public label: string = '';
  @Input()
  public key: string = '';
  @Input()
  public currentName: string = '';
  @Input()
  public data: any;
  @Input()
  public isOpen: boolean = false;
  @Input()
  public showSearch: boolean = false;
  @Input()
  public searchIndex: number = 0;

  @Output()
  public select: EventEmitter<any> = new EventEmitter();
  @Output()
  public open: EventEmitter<any> = new EventEmitter();

  public searchForm: FormGroup = this.fb.group({
    q: [''],
  });

  public get q(): AbstractControl | null {
    return this.searchForm!.get('q');
  }

  public searchValue: string = '';

  constructor(
    private fb: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.q?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        this.searchValue = value;
      });
  }

  public toggleList(): void {
    this.searchValue = '';
    this.isOpen = !this.isOpen;
    this.open.next(this.isOpen);
  }

  public selectElement($event: any): void {
    this.key = $event.value[0];
    this.currentName = this.data.find((item: any) => item.id === this.key)?.name;

    this.select.next($event);
  }
}

import { CdkListboxModule } from '@angular/cdk/listbox';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FieldModule } from '../field/field.module';
import { FieldSelectComponent, SearchFilterPipe } from './field-select/field-select.component';
import { FieldSelectListComponent } from './field-select-list/field-select-list.component';
import { FieldSelectRoundedComponent } from './field-select-rounded/field-select-rounded.component';

@NgModule({
  exports: [
    FieldSelectComponent,
    FieldSelectListComponent,
    FieldSelectRoundedComponent,
  ],
  declarations: [
    FieldSelectComponent,
    FieldSelectListComponent,
    FieldSelectComponent,
    FieldSelectRoundedComponent,
    SearchFilterPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OverlayModule,
    CdkListboxModule,
    FieldModule,
  ],
})
export class FieldSelectModule { }

<button type="button" class="dots" (click)="toggleMenu(trigger)" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_557_3194)">
      <path d="M8.00032 2.66667C8.7367 2.66667 9.33365 2.06971 9.33365 1.33333C9.33365 0.596954 8.7367 0 8.00032 0C7.26394 0 6.66699 0.596954 6.66699 1.33333C6.66699 2.06971 7.26394 2.66667 8.00032 2.66667Z" fill="#595959"/>
      <path d="M8.00032 9.33353C8.7367 9.33353 9.33365 8.73658 9.33365 8.0002C9.33365 7.26382 8.7367 6.66687 8.00032 6.66687C7.26394 6.66687 6.66699 7.26382 6.66699 8.0002C6.66699 8.73658 7.26394 9.33353 8.00032 9.33353Z" fill="#595959"/>
      <path d="M8.00032 15.9998C8.7367 15.9998 9.33365 15.4029 9.33365 14.6665C9.33365 13.9301 8.7367 13.3331 8.00032 13.3331C7.26394 13.3331 6.66699 13.9301 6.66699 14.6665C6.66699 15.4029 7.26394 15.9998 8.00032 15.9998Z" fill="#595959"/>
    </g>
    <defs>
      <clipPath id="clip0_557_3194">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>    
</button>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/guards/auth.guard';
import { NonAuthGuard } from './auth/guards/non-auth.guard';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { RedirectToNewResetPasswordUrlPath } from './redirect-to-new-reset-password-url-path';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/logowanie',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    redirectTo: '/panel/a/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'user/reset-password',
    canActivate: [
      RedirectToNewResetPasswordUrlPath,
    ],
    loadChildren: () => import('./remind-password/remind-password.module').then(m => m.RemindPasswordModule),
  },
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'logowanie',
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren: () => import('./static/static.module').then(m => m.StaticModule),
      },
      {
        path: '',
        component: AuthLayoutComponent,
        canActivate: [NonAuthGuard],
        children: [
          {
            path: 'logowanie',
            loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
            title: 'Responsiblee — Logowanie',
          },
          {
            path: 'rejestracja',
            loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
            title: 'Responsiblee — Rejestracja',
          },
          {
            path: 'przypomnij-haslo',
            loadChildren: () => import('./remind-password/remind-password.module').then(m => m.RemindPasswordModule),
            title: 'Responsiblee — Przypomnij hasło',
          },
        ],
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('./authenticated/authenticated.module').then(m => m.AuthenticatedModule),
      },
    ],
  },
];

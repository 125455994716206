import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FieldComponent } from './field.component';
import { FieldErrorComponent } from './field-error/field-error.component';
import { FieldMaskComponent } from './field-mask/field-mask.component';

@NgModule({
  declarations: [FieldComponent, FieldErrorComponent, FieldMaskComponent],
  exports: [FieldComponent, FieldErrorComponent, FieldMaskComponent],
  imports: [
    CommonModule,
  ],
})
export class FieldModule { }

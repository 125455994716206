import { APP_BASE_HREF, PlatformLocation, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localePL from '@angular/common/locales/pl';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';

import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import * as Sentry from '@sentry/angular';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ToastComponent, ToastModule } from 'projects/ui/src/public-api';
import { JwtInterceptor } from 'src/app/interceptors/jwt.interceptor';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppInterceptorService } from './app.interceptor';
import { APP_ROUTES } from './app-routing.module';
import { AuthState } from './auth/store/auth.state';
import { HttpErrorInterceptor } from './interceptors/httpErrorInterceptor';
import { LayoutModule } from './layout/layout.module';
import { RedirectToNewResetPasswordUrlPath } from './redirect-to-new-reset-password-url-path';

registerLocaleData(localePL);
registerLocaleData(localeEN);

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory(): any {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    ToastModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      closeButton: false,
      toastClass: '',
    }),
    NgxsModule.forRoot([AuthState], {
      developmentMode: !environment.production,
      selectorOptions: {
        injectContainerState: false,
        suppressErrors: false,
      },
    }),
    RouterModule.forRoot(APP_ROUTES, {
      // enableTracing: !environment.production
      onSameUrlNavigation: 'reload',
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [ToastrService],
    },
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService, multi: true,
      deps: [LOCALE_ID],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    JwtHelperService,
    RedirectToNewResetPasswordUrlPath,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button/button.module';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  exports: [
    ModalComponent,
  ],
  declarations: [
    ModalComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
  ],
})
export class ModalModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContentCardComponent } from './content-card/content-card.component';

@NgModule({
  declarations: [
    ContentCardComponent,
  ],
  exports: [
    ContentCardComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class ContentCardModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LottieModule } from 'ngx-lottie';

import { CardModule } from '../card/card.module';
import { EmptyStateComponent } from './empty-state/empty-state.component';

@NgModule({
  declarations: [
    EmptyStateComponent,
  ],
  imports: [
    CommonModule,
    CardModule,
    LottieModule,
  ],
  exports: [
    EmptyStateComponent,
  ],
})
export class EmptyStateModule { }

import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button/button.module';
import { OnboardingComponent } from './onboarding/onboarding.component';

@NgModule({
  exports: [
    OnboardingComponent,
  ],
  declarations: [
    OnboardingComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
  ],
})
export class OnboardingModule { }

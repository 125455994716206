import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { ContextMenuService } from '../context-menu.service';

@Component({
  selector: 'lib-context-menu-dots',
  templateUrl: './context-menu-dots.component.html',
  styleUrls: ['./context-menu-dots.component.scss'],
})
export class ContextMenuDotsComponent implements OnInit {
  @Input()
  public data: any;

  public toggleContextMenu: boolean = false;

  constructor(
    private contextMenuService: ContextMenuService
  ) { }

  public ngOnInit(): void {
  }

  public toggleMenu(trigger: any): void {
    this.contextMenuService.open({
      data: {...{trigger}, ...this.data},
    });
  }

}

export enum ValidatorsPatterns {
  EMAIL_REGEX = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$',
  NAME_REGEX = '^[A-Z,a-z,0-9,żźćńółęąśŻŹĆĄŚĘŁÓŃ,_. -]+$',
  PASSWORD_REGEX_1 = '',
  PASSWORD_REGEX_2 = '',
  PASSWORD_REGEX_3 = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+=\\[\\]\\\\;\'":<>.,{}?])[A-Za-z\\d!@#$%^&*()_+=\\[\\]\\\\;\'":<>.,{}?]{8,}$',
  MIN_ONE_SMALL_LETTER = '[a-z]+',
  MIN_ONE_LARGE_LETTER = '[A-Z]+',
  MIN_ONE_NUMBER = '[0-9]+',
  MIN_ONE_SPECIAL_SIGN = '[!@#$%^&*()_+=\\[\\]\\\\;\'":<>.,{}?]+',
  MIN_PASSOWRD_LENGTH = '[A-Za-z\\d!@#$%^&*()_+=\\[\\]\\\\;\'":<>.,{}?]{8,}$',
  POST_CODE_REGEX = '^[0-9]{2}-[0-9]{3}$',
  VIN_REGEX = '^[A-HJ-NPR-Z0-9]{17}$',
  NUMBERS = '^[0-9]*(?:[.][0-9]{1,3})?$',
  NUMBERS_DECIMAL_2 = '^[0-9]*(?:[.][0-9]{1,2})?$',
  NUMBERS_1 = '^[0-9]*$',
  NUMBERS_NEGATIVES = '^[0-9-]*(?:[.][0-9]{1,3})?$',
  DECIMAL_3 = '^[0-9]{1,100}([.][0-9]{1,3})?$',
  REGEX_PHONE_VALIDATOR = '^\\+?\\d{1,4}?[-.\\s]?\\(?\\d{1,3}?\\)?[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,9}$',
}

export function itemsPerPageFun(): number {
  return sessionStorage.getItem('itemsPerPage') ? Number(sessionStorage.getItem('itemsPerPage')) : 10;
}

export interface YearsMap {
  id: number;
  label: string;
  isChecked: boolean;
}


export const yearsMap: YearsMap[] = [
  {
    id: 2030,
    label: '2030',
    isChecked: true,
  },
  {
    id: 2029,
    label: '2029',
    isChecked: true,
  },
  {
    id: 2028,
    label: '2028',
    isChecked: true,
  },
  {
    id: 2027,
    label: '2027',
    isChecked: true,
  },
  {
    id: 2026,
    label: '2026',
    isChecked: true,
  },
  {
    id: 2025,
    label: '2025',
    isChecked: true,
  },
  {
    id: 2024,
    label: '2024',
    isChecked: true,
  },
  {
    id: 2023,
    label: '2023',
    isChecked: true,
  },
  {
    id: 2022,
    label: '2022',
    isChecked: false,
  },
  {
    id: 2021,
    label: '2021',
    isChecked: false,
  },
  {
    id: 2020,
    label: '2020',
    isChecked: false,
  },
  {
    id: 2019,
    label: '2019',
    isChecked: false,
  },
];

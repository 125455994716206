import { Injectable } from '@angular/core';
import { browser } from 'protractor';

@Injectable({ providedIn: 'root' })
export class CookiesService {

  constructor() {}

  get(name: string): string | undefined {
    if (document.cookie !== '') {
      const cookies = document.cookie.split(/; */);

      for (const cookie of cookies) {
        const [ cookieName, cookieVal ] = cookie.split('=');
        if (cookieName === decodeURIComponent(name)) {
          return decodeURIComponent(cookieVal);
        }
      }
    }

    return undefined;
  }

  remove(name: string): void {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

}

<div class="custom-date" [ngClass]="{'not-empty': currentValue, 'is-open': isOpen}">
  <div class="label"><ng-content select="label"></ng-content></div>
  
  <div class="field">
    <input type="text" class="value" [value]="currentValue" (focus)="toggleDatePicker()" cdkOverlayOrigin #trigger="cdkOverlayOrigin" tabindex="1">
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3333 1.33333H12V1C12 0.734784 11.8946 0.48043 11.7071 0.292893C11.5196 0.105357 11.2652 0 11 0V0C10.7348 0 10.4804 0.105357 10.2929 0.292893C10.1054 0.48043 10 0.734784 10 1V1.33333H6V1C6 0.734784 5.89464 0.48043 5.70711 0.292893C5.51957 0.105357 5.26522 0 5 0V0C4.73478 0 4.48043 0.105357 4.29289 0.292893C4.10536 0.48043 4 0.734784 4 1V1.33333H3.66667C2.69421 1.33333 1.76158 1.71964 1.07394 2.40728C0.386308 3.09491 0 4.02754 0 5L0 12.3333C0 13.3058 0.386308 14.2384 1.07394 14.9261C1.76158 15.6137 2.69421 16 3.66667 16H12.3333C13.3058 16 14.2384 15.6137 14.9261 14.9261C15.6137 14.2384 16 13.3058 16 12.3333V5C16 4.02754 15.6137 3.09491 14.9261 2.40728C14.2384 1.71964 13.3058 1.33333 12.3333 1.33333ZM12.3333 14H3.66667C3.22464 14 2.80072 13.8244 2.48816 13.5118C2.17559 13.1993 2 12.7754 2 12.3333V6.66667H14V12.3333C14 12.7754 13.8244 13.1993 13.5118 13.5118C13.1993 13.8244 12.7754 14 12.3333 14Z" fill="#808080"/>
    </svg>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'no-background'"
    (backdropClick)="toggleDatePicker()"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayDisableClose]="false"
    [cdkConnectedOverlayLockPosition]="false"
  >
    <lib-air-datepicker 
      [view]="view" 
      [minView]="minView" 
      [minDate]="minDate"
      [maxDate]="maxDate"
      [startDate]="startDate"
      [range]="range" 
      (select)="onSelect($event)">
    </lib-air-datepicker>
  </ng-template>
  <ng-content select="lib-field-error"></ng-content>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import AirDatepicker from 'air-datepicker';
import localePl from 'air-datepicker/locale/pl';

import { ViewDatepicker } from '../field-date-model';

@Component({
  selector: 'lib-air-datepicker',
  templateUrl: './air-datepicker.component.html',
  styleUrls: ['./air-datepicker.component.scss'],
})
export class AirDatepickerComponent implements OnInit {
  @Input()
  public dateFormatViewValue: string = 'yyyy-MM-dd';
  @Input()
  public view: ViewDatepicker = ViewDatepicker.DAYS;
  @Input()
  public minView!: ViewDatepicker;
  @Input()
  public range!: boolean;
  @Input()
  public minDate: string = '';
  @Input()
  public maxDate: string = '';
  @Input()
  public startDate: string = '';
  @Output()
  public select: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public ngOnInit(): void {
    const select: EventEmitter<any> = this.select;

    new AirDatepicker('#air-datepicker', {
      locale: localePl,
      onSelect({date, formattedDate, datepicker}: any) {
        select.emit({date, formattedDate, datepicker});
      },
      dateFormat: this.dateFormatViewValue,
      view: this.view,
      minView: this.minView,
      range: this.range,
      minDate: this.minDate,
      maxDate: this.maxDate,
      startDate: this.startDate,
    } as any);
  }

}

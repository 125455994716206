import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { provideSvgIcons, SvgIconComponent } from '@ngneat/svg-icon';
import { arrowSmallDownIcon } from 'projects/responsiblee/src/svg/arrow-small-down';
import { arrowSmallUpIcon } from 'projects/responsiblee/src/svg/arrow-small-up';

import { ButtonModule } from '../button/button.module';
import { FieldModule } from '../field/field.module';
import { FieldCheckboxModule } from '../field-checkbox/field-checkbox.module';
import { FieldMultiselectModule } from '../field-multiselect/field-multiselect.module';
import { FieldSelectModule } from '../field-select/field-select.module';
import { FieldCheckboxTreeComponent } from './field-checkbox-tree/field-checkbox-tree.component';

@NgModule({
  declarations: [
    FieldCheckboxTreeComponent,
  ],
  exports: [
    FieldCheckboxTreeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FieldModule,
    FieldCheckboxModule,
    FieldSelectModule,
    ButtonModule,
    SvgIconComponent,
    FieldMultiselectModule,
  ],
  providers: [
    provideSvgIcons([
      arrowSmallUpIcon,
      arrowSmallDownIcon,
    ]),
  ],
})
export class FieldCheckboxTreeModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button/button.module';
import { TrialBarComponent } from './trial-bar/trial-bar.component';

@NgModule({
  exports: [
    TrialBarComponent,
  ],
  declarations: [
    TrialBarComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
  ],
})
export class TrialBarModule { }

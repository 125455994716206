<header>
  <lib-logo-responsiblee></lib-logo-responsiblee>
  <ul class="change-lang">
    <li *ngIf="lang === 'en'"><a href="/" (click)="setLang('pl')">PL</a></li>
    <li *ngIf="lang === 'pl'"><a href="/en" (click)="setLang('en')">EN</a></li>
  </ul>
</header>

<router-outlet></router-outlet>

<footer>
  <nav>
    <ul>
      <li><a routerLink="/polityka-prywatnosci" i18n>Polityka prywatności</a></li>
      <li><a routerLink="/regulamin" i18n>Regulamin</a></li>
      <li><a href="https://responsiblee.net/" target="_blank" i18n>Strona platformy</a></li>
    </ul>
  </nav>
</footer>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContentLoaderModule } from '@ngneat/content-loader';

import { TableComponent } from './table/table.component';
import { TableContentLoaderComponent } from './table-content-loader/table-content-loader.component';

@NgModule({
  exports: [
    TableComponent,
    TableContentLoaderComponent,
  ],
  declarations: [
    TableComponent,
    TableContentLoaderComponent,
  ],
  imports: [
    CommonModule,
    ContentLoaderModule,
  ],
})
export class TableModule { }

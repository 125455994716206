import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { map, Observable } from 'rxjs';

import { BaseApiService } from 'src/app/shared/services/api/base-api/base-api.service';

import { AuthActions } from './store/auth.actions';

export interface LoginModelDomainGet {
  data: {
    name: string;
    surname: string;
    email: string;
    access: string;
    refresh: string;
  };
}

export interface LoginModelDomainPost {
  email: string;
  password: string;
}

export interface RegisterModelDomainGet {
  data: {
    name: string;
    surname: string;
    email: string;
    access: string;
    refresh: string;
  };
}

export interface RegisterModelDomainPost {
  email: string;
  password: string;
}

export interface RefreshAccessModelDomainGet {
  access: string;
}

export interface RefreshAccessModelDomainPost {
  access: string | null | undefined;
  refresh: string | null | undefined;
}

export interface UserModelDomainPost {
  name?: string;
  surname?: string;
  phone?: string;
}

export interface EmailModelDomainPost {
  new_email: string;
}

export interface PasswordModelDomainPost {
  password?: string;
  new_password?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseApiService {
  constructor(
    protected override http: HttpClient,
    private store: Store
  ) {
    super(http);
  }

  public login(payload: LoginModelDomainPost): Observable<LoginModelDomainGet> {
    return this.post('/user/login/', payload).pipe(
      map((response: LoginModelDomainGet): LoginModelDomainGet => response)
    );
  }

  public register(payload: RegisterModelDomainPost): Observable<RegisterModelDomainGet> {
    return this.post('/user/register/', payload).pipe(
      map((response: RegisterModelDomainGet): RegisterModelDomainGet => response)
    );
  }

  public refreshAccess(payload: RefreshAccessModelDomainPost): Observable<RefreshAccessModelDomainGet> {
    return this.post('/user/login/refresh/', payload).pipe(
      map((response: RefreshAccessModelDomainGet): RefreshAccessModelDomainGet => response)
    );
  }

  public changeTokens(refresh: string | null, access: string | null): void {
    if (refresh) {
      sessionStorage.setItem('refresh', refresh);
    }
    if (access) {
      sessionStorage.setItem('access', access);
    }
  }
}

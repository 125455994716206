<div class="main-pagination">
  <div class="items">
    <lib-field class="per-page">
      <label for="itemsPerPage" i18n>Pokaż</label>
      <select [formControl]="itemsPerPage" id="itemsPerPage">
        <option [value]="10" i18n>10 wynik</option>
        <option [value]="20" i18n>20 wyników</option>
        <option [value]="50" i18n>50 wyników</option>
        <option [value]="100" i18n>100 wyników</option>
      </select>
    </lib-field>

    <div class="items-summary" *ngIf="totalCount$ | async as totalCount">
      <ng-container i18n>Strona</ng-container>
      {{currentPage}}
      <ng-container i18n>z</ng-container>
      {{round(totalCount / itemsPerPage.value)}}
    </div>
  </div>


  <ng-content></ng-content>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { modalAnimation, modaOverlayAnimation } from './modal.animation';

@Component({
  selector: 'lib-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [...modalAnimation, ...modaOverlayAnimation],
})
export class ModalComponent implements OnInit {
  @Input()
  public showClose: boolean = true;
  @Input()
  public title: string = '';
  @Input()
  public subtitle: string = '';
  @Input()
  public footer: boolean = true;
  @Input()
  public cutomHideModal: boolean = false;
  @Output()
  public customNavigate: EventEmitter<any> = new EventEmitter();

  public show: boolean = true;

  constructor(
    private router: Router
  ) { }

  public ngOnInit(): void {
  }

  public close(): void {
    this.show = false;
  }

  public hideModal(): void {
    if (!this.show) {
      if (this.cutomHideModal) {
        this.customNavigate.emit(true);
      } else {
        this.router.navigate(['/panel', {
          outlets: {
            modal: null,
          },
        }], {
          queryParamsHandling: 'preserve',
        });
      }
    }
  }

}

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContextMenuService } from './context-menu.service';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { ContextMenuDotsComponent } from './context-menu-dots/context-menu-dots.component';
import { ContextMenuRoundedComponent } from './context-menu-rounded/context-menu-rounded.component';
import { CONTEXT_MENU_DATA } from './context-menu-tokens';

@NgModule({
  exports: [
    ContextMenuComponent,
    ContextMenuDotsComponent,
    ContextMenuRoundedComponent,
  ],
  declarations: [
    ContextMenuComponent,
    ContextMenuDotsComponent,
    ContextMenuRoundedComponent,
  ],
  imports: [
    CommonModule,
    OverlayModule,
  ],
  providers: [
    ContextMenuService,
    {
      provide: CONTEXT_MENU_DATA,
      useValue: '',
    },
  ],
})
export class ContextMenuModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HeaderH1Component } from './header-h1/header-h1.component';

@NgModule({
  exports: [
    HeaderH1Component,
  ],
  declarations: [
    HeaderH1Component,
  ],
  imports: [
    CommonModule,
  ],
})
export class HeadersModule { }

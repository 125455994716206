<div class="confirm-modal">
  <h4>{{data?.title}}</h4>
  <p *ngIf="data?.subtitle">{{data?.subtitle}}</p>

  <div class="buttons">
    <lib-button>
      <button type="button" class="button button-secondary" (click)="cancel()" i18n>Anuluj</button>
    </lib-button>
    <lib-button>
      <button type="button" class="button button-primary" (click)="success()" i18n>Ok</button>
    </lib-button>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FieldCheckboxGroupComponent } from './field-checkbox-group/field-checkbox-group.component';

@NgModule({
  exports: [
    FieldCheckboxGroupComponent,
  ],
  declarations: [
    FieldCheckboxGroupComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class FieldCheckboxGroupModule { }

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://a5dadd2f87a54120bba82bbc7ab8aaeb@o4504032465584128.ingest.sentry.io/4504237623148544',
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['localhost', 'https://app.dev.responsiblee.net', 'https://app.staging.responsiblee.net', 'https://app.responsiblee.net'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  environment: environment.env,
  tracesSampleRate: environment.env === 'production' ? 0.1 : 1.0,
});

Sentry.configureScope((scope) => {
  scope.setUser(null);
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button/button.module';
import { FieldFileComponent, FileNamePipe } from './field-file/field-file.component';

@NgModule({
  declarations: [
    FieldFileComponent,
    FileNamePipe,
  ],
  exports: [
    FieldFileComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
  ],
})
export class FieldFileModule { }

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-logo-responsiblee',
  templateUrl: './logo-responsiblee.component.html',
})
export class LogoResponsibleeComponent {
  @Input()
  public signet: boolean = false;
}

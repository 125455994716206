<div class="modal" [@modalAnimation] (@modalAnimation.done)="hideModal($event)" *ngIf="show">
  <div class="modal-header">
    <div class="header">
      <h2>{{title}}</h2>
      <ng-content select="[modal-title]"></ng-content>
      <p>{{subtitle}} <ng-content select="[modal-subtitle]"></ng-content></p>
    </div>

    <lib-button *ngIf="showClose">
      <button type="button" class="button button-medium button-secondary" (click)="close()">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.99988 1C8.87486 0.87502 8.70532 0.80481 8.52854 0.80481C8.35177 0.80481 8.18223 0.87502 8.05721 1L4.99988 4.05733L1.94255 1C1.81753 0.87502 1.64799 0.80481 1.47121 0.80481C1.29444 0.80481 1.1249 0.87502 0.999879 1C0.874898 1.12502 0.804688 1.29456 0.804688 1.47133C0.804688 1.64811 0.874898 1.81765 0.999879 1.94267L4.05721 5L0.999879 8.05733C0.874898 8.18235 0.804688 8.35189 0.804688 8.52867C0.804688 8.70544 0.874898 8.87498 0.999879 9C1.1249 9.12498 1.29444 9.19519 1.47121 9.19519C1.64799 9.19519 1.81753 9.12498 1.94255 9L4.99988 5.94267L8.05721 9C8.18223 9.12498 8.35177 9.19519 8.52854 9.19519C8.70532 9.19519 8.87486 9.12498 8.99988 9C9.12486 8.87498 9.19507 8.70544 9.19507 8.52867C9.19507 8.35189 9.12486 8.18235 8.99988 8.05733L5.94254 5L8.99988 1.94267C9.12486 1.81765 9.19507 1.64811 9.19507 1.47133C9.19507 1.29456 9.12486 1.12502 8.99988 1Z"/>
        </svg>
      </button>
    </lib-button>
  </div>

  <div class="modal-content">
    <ng-content select="[modal-content]"></ng-content>
  </div>

  <div class="modal-footer" *ngIf="footer">
    <ng-content select="[modal-footer]"></ng-content>
  </div>
</div>
<div class="modal-overlay" *ngIf="show" (click)="close()" [@modaOverlayAnimation]></div>

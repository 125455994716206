import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { Observable, tap } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';
import * as Sentry from '@sentry/angular';

import { CookiesService } from '../../../../../../src/app/shared/services/utils/cookies/cookies.service';
import { AuthService, LoginModelDomainGet } from '../auth.service';
import { AuthActions } from './auth.actions';

export interface AuthStateModel {
  name: string;
  surname: string;
  email: string;
}

const emptyAuthStateModel: AuthStateModel = {
  name: '',
  surname: '',
  email: '',
};

@State<AuthStateModel>({
  name: 'login',
  defaults: { ...emptyAuthStateModel },
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private jwtHelperService: JwtHelperService,
    private store: Store,
    private router: Router,
    private cookies: CookiesService
  ) {
  }

  @Selector()
  public static firstName(state: AuthStateModel): string | null | undefined {
    return state?.name;
  }

  @Selector()
  public static surname(state: AuthStateModel): string | null | undefined {
    return state?.surname;
  }

  @Selector()
  public static email(state: AuthStateModel): string | null | undefined {
    return state?.email;
  }

  @Action(AuthActions.LoadUser)
  public loadUser(ctx: StateContext<AuthStateModel>): void {
    if (sessionStorage.getItem('access')) {
      const decoded: AuthStateModel = this.jwtHelperService.decodeToken(sessionStorage.getItem('access')!).user;
      ctx.patchState({
        name: decoded.name,
        surname: decoded.surname,
        email: decoded.email,
      });
      Sentry.configureScope((scope) => {
        scope.setUser({ email: decoded.email });
      });
    }
  }

  @Action(AuthActions.Login)
  public login(ctx: StateContext<AuthStateModel>, action: AuthActions.Login): Observable<LoginModelDomainGet> {
    return this.authService.login(action.credentials).pipe(
      tap((response: LoginModelDomainGet) => {
        sessionStorage.setItem('access', response.data.access);
        sessionStorage.setItem('refresh', response.data.refresh);
        ctx.patchState({
          name: response.data.name,
          surname: response.data.surname,
          email: response.data.email,
        });
      })
    );
  }

  @Action(AuthActions.Register)
  public register(ctx: StateContext<AuthStateModel>, action: AuthActions.Register): Observable<LoginModelDomainGet> {
    return this.authService.register(action.payload);
  }

  @Action(AuthActions.LoginByGoogle)
  public loginByGoogle(ctx: StateContext<AuthStateModel>, action: AuthActions.LoginByGoogle): void {
    sessionStorage.setItem('access', action.access);
    sessionStorage.setItem('refresh', action.refresh);
    const decoded: AuthStateModel = this.jwtHelperService.decodeToken(action.access).user;
    ctx.patchState({
      name: decoded.name,
      surname: decoded.surname,
      email: decoded.email,
    });
  }

  @Action(AuthActions.Logout)
  public logout(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState(emptyAuthStateModel);
    sessionStorage.clear();
    this.store.reset({
      login: emptyAuthStateModel,
    });
    Sentry.configureScope((scope) => {
      scope.setUser(null);
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TruncatePipe } from './truncate.pipe';

@NgModule({
  exports: [TruncatePipe],
  declarations: [TruncatePipe],
  imports: [
    CommonModule,
  ],
})
export class TruncateModule {
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TabListComponent } from './tab-list/tab-list.component';

@NgModule({
  exports: [
    TabListComponent,
  ],
  declarations: [
    TabListComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class TabsModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {FieldSwitchComponent} from './field-switch.component';

@NgModule({
  declarations: [FieldSwitchComponent],
  exports: [FieldSwitchComponent],
  imports: [
    CommonModule,
  ],
})
export class FieldSwitchModule { }

import { ChangeDetectionStrategy, Component, Input  } from '@angular/core';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input()
  public isLoading: boolean = false;
  @Input()
  public buttonType: string = 'button';
}

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button/button.module';
import { ConfirmModalService } from './confirm-modal.service';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@NgModule({
  exports: [
    ConfirmModalComponent,
  ],
  declarations: [
    ConfirmModalComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    OverlayModule,
  ],
  providers: [
    ConfirmModalService,
  ],
})
export class ConfirmModalModule { }

import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, EventEmitter, Injectable, Injector, Output } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ContextMenuComponent } from './context-menu/context-menu.component';
import { ContextMenuRef } from './context-menu-ref';
import { CONTEXT_MENU_DATA } from './context-menu-tokens';

interface ContextMenuConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: {
    trigger: any;
  };
}

const DEFAULT_CONFIG: ContextMenuConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'context-menu-panel',
  data: undefined,
};

@UntilDestroy()
@Injectable()
export class ContextMenuService {
  public containerRef!: ComponentRef<ContextMenuComponent>;
  public trigger!: any;

  @Output()
  public dialogClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private injector: Injector,
    private overlay: Overlay
  ) { }

  public open(config: ContextMenuConfig = {}): void {
    this.trigger = config?.data?.trigger;
    // Override default configuration
    const dialogConfig: any = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef: any = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef: any = new ContextMenuRef(overlayRef);

    const overlayComponent: any = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    overlayRef.backdropClick().subscribe((_: any) => {
      dialogRef.close();
      this.dialogClosed.emit(true);
    });

    return dialogRef;
  }

  private createOverlay(config: ContextMenuConfig): any {
    const overlayConfig: any = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: ContextMenuConfig, dialogRef: ContextMenuRef): any {
    const injector: any = this.createInjector(config, dialogRef);

    const containerPortal: ComponentPortal<ContextMenuComponent> = new ComponentPortal(ContextMenuComponent, null, injector);
    const containerRef: ComponentRef<ContextMenuComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: ContextMenuConfig, dialogRef: ContextMenuRef): PortalInjector {
    const injectionTokens: any = new WeakMap();

    injectionTokens.set(ContextMenuRef, dialogRef);
    injectionTokens.set(CONTEXT_MENU_DATA, config.data);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: ContextMenuConfig): OverlayConfig {
    const positionStrategy: any = this.overlay.position()
      .flexibleConnectedTo(this.trigger.elementRef)
      .withPositions([{
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      }]);

    const overlayConfig: any = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    return overlayConfig;
  }
}

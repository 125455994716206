import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';

export class PasswordValidators {
  constructor() {}

  public static mustMatch(controlName: string, matchingControlName: string): ValidationErrors | null {
    return (formGroup: FormGroup) => {
      const control: AbstractControl = formGroup.controls[controlName];
      const matchingControl: AbstractControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }
}

import { AbstractControl, ValidationErrors } from '@angular/forms';

const checksum: (number: string, weights: number[]) => boolean = (number: string, weights: number[]): boolean => {
  const max: number = number.length - 1;
  let sum: number = 0;

  for (let i: number = 0; i < max; ++i) {
    const n: number = parseInt(number.charAt(i), 10);
    sum += n * (weights[i] as number);
  }

  const control: any = sum % 11;
  const resultSum: any = control === 10 ? 0 : control;
  const lastDigit: any = parseInt(number.slice(-1), 10);

  return resultSum === lastDigit;
};

const checkRegon: (regon: string) => boolean = (regon: string): boolean => {
  const reg: any = /^[0-9]{9,14}$/u;
  if (!reg.test(regon)) {
      return false;
  }

  const weights9: number[] = [8, 9, 2, 3, 4, 5, 6, 7];

  if (regon.length === 9) {
    return checksum(regon, weights9);
  }

  const weights14: number[] = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
  return checksum(regon.slice(0, 9), weights9) && checksum(regon, weights14);
};

export const regonValidator: (control: AbstractControl) => ValidationErrors | null = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value || control.value.length === 0) {
    return null;
  }

  const isRegonValid: boolean = checkRegon(`${control.value}`);

  return !isRegonValid ? { regon: control.value } : null;
};

import {
  LoginModelDomainPost,
} from '../auth.service';

export namespace AuthActions {
  export class LoadUser {
    public static readonly type: string = '[Auth] Load User';

    constructor() {}
  }
  export class Login {
    public static readonly type: string = '[Auth] Login';

    constructor(
      public credentials: LoginModelDomainPost
    ) {
    }
  }
  export class LoginByGoogle {
    public static readonly type: string = '[Auth] Login by Google';

    constructor(
      public access: string,
      public refresh: string
    ) {
    }
  }
  export class Register {
    public static readonly type: string = '[Auth] Register';

    constructor(
      public payload: LoginModelDomainPost
    ) {
    }
  }
  export class RefreshAccess {
    public static readonly type: string = '[Auth] Refresh Access';

    constructor(
      public access: string
    ) {
    }
  }
  export class Logout {
    public static readonly type: string = '[Auth] Logout';

    constructor() {}
  }
}

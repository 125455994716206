import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';

import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ConfirmModalRef } from './confirm-modal-ref';
import { CONFIRM_MODAL_DATA } from './confirm-modal-tokens';

interface ConfirmModalConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: {
    title?: string;
    subtitle?: string;
    success?: any;
  };
}

const DEFAULT_CONFIG: ConfirmModalConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'confirm-modal-panel',
  data: undefined,
};

@Injectable()
export class ConfirmModalService {

  constructor(
    private injector: Injector,
    private overlay: Overlay) { }

  public open(config: ConfirmModalConfig = {}): void {
    // Override default configuration
    const dialogConfig: any = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef: any = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef: any = new ConfirmModalRef(overlayRef);

    const overlayComponent: any = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    overlayRef.backdropClick().subscribe((_: any) => dialogRef.close());

    return dialogRef;
  }

  private createOverlay(config: ConfirmModalConfig): any {
    const overlayConfig: any = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: ConfirmModalConfig, dialogRef: ConfirmModalRef): any {
    const injector: any = this.createInjector(config, dialogRef);

    const containerPortal: ComponentPortal<ConfirmModalComponent> = new ComponentPortal(ConfirmModalComponent, null, injector);
    const containerRef: ComponentRef<ConfirmModalComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: ConfirmModalConfig, dialogRef: ConfirmModalRef): PortalInjector {
    const injectionTokens: any = new WeakMap();

    injectionTokens.set(ConfirmModalRef, dialogRef);
    injectionTokens.set(CONFIRM_MODAL_DATA, config.data);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: ConfirmModalConfig): OverlayConfig {
    const positionStrategy: any = this.overlay.position()
      .global()
      .centerHorizontally();

    const overlayConfig: any = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    return overlayConfig;
  }
}

import { AfterContentInit, Component, ContentChild } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Destroyer } from '../destroyer/destroyer';

@Component({
  selector: 'lib-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent extends Destroyer implements AfterContentInit {
  @ContentChild(NgControl, { static: true })
  public control: NgControl | undefined;

  public isEmpty$: Observable<boolean> = new Observable<boolean>();

  constructor() {
    super();
  }

  public ngAfterContentInit(): void {
    if (!this.control) {
      return;
    }

    this.isEmpty$ = this.control!.valueChanges!
      .pipe(
        startWith(this.control.value),
        map((value: any) => !value && value !== 0)
      );
  }
}

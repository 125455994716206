import { CdkListboxModule } from '@angular/cdk/listbox';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { provideSvgIcons, SvgIconComponent } from '@ngneat/svg-icon';
import { arrowSmallDownIcon } from 'projects/responsiblee/src/svg/arrow-small-down';
import { arrowSmallUpIcon } from 'projects/responsiblee/src/svg/arrow-small-up';

import { ButtonModule } from '../button/button.module';
import { FieldMultiselectComponent } from './field-multiselect/field-multiselect.component';

@NgModule({
  declarations: [
    FieldMultiselectComponent,
  ],
  exports: [
    FieldMultiselectComponent,
  ],
  imports: [
    CommonModule,
    SvgIconComponent,
    OverlayModule,
    CdkListboxModule,
    ButtonModule,
  ],
  providers: [
    provideSvgIcons([
      arrowSmallUpIcon,
      arrowSmallDownIcon,
    ]),
  ],
})
export class FieldMultiselectModule { }

import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { MessageType } from '../../message/message/message.model';

@Component({
  selector: 'lib-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      state('removed', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('200ms ease-in-out', keyframes([
        style({
          transform: 'translate3d(10%, 0, 0)',
          opacity: 0,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('200ms ease-in-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(10%, 0, 0)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class ToastComponent extends Toast implements OnInit {
  @Input()
  public messageType: MessageType = MessageType.ERROR;

  public messagesTypes: typeof MessageType = MessageType;

  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.toastrService.clear();
    }, 5000);
  }

  public action(event: Event): boolean {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button/button.module';
import { CookiesBarComponent } from './cookies-bar/cookies-bar.component';

@NgModule({
  declarations: [
    CookiesBarComponent,
  ],
  exports: [
    CookiesBarComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
  ],
})
export class CookiesBarModule { }

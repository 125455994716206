import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FixedSubmitComponent } from './fixed-submit/fixed-submit.component';

@NgModule({
  exports: [
    FixedSubmitComponent,
  ],
  declarations: [
    FixedSubmitComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class FixedSubmitModule { }

import { animate, style, transition, trigger } from '@angular/animations';

export const modalAnimation: any = [
  trigger(
    'modalAnimation',
    [
      transition(
        ':enter',
        [
          style({ opacity: 0, transform: 'translate3d(0, 60px, 0)' }),
          animate('.2s ease-in-out', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
        ]
      ),
      transition(
        ':leave',
        [
          style({ opacity: 1 }),
          animate('.2s ease-in-out', style({ opacity: 0 })),
        ]
      ),
    ]
  ),
];

export const modaOverlayAnimation: any = [
  trigger(
    'modaOverlayAnimation',
    [
      transition(
        ':enter',
        [
          style({ opacity: 0 }),
          animate('.3s ease-in-out', style({ opacity: 1 })),
        ]
      ),
      transition(
        ':leave',
        [
          style({ opacity: 1 }),
          animate('.2s ease-in-out', style({ opacity: 0 })),
        ]
      ),
    ]
  ),
];

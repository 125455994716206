import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MessageComponent } from './message/message.component';

@NgModule({
  exports: [
    MessageComponent,
  ],
  declarations: [
    MessageComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class MessageModule { }

import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit {
  public lang: string = this.locale;

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  public ngOnInit(): void {
  }

  public setLang(lang: string): void {
    localStorage.setItem('lang', lang);
  }

}

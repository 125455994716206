import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ViewDatepicker } from '../field-date-model';

@Component({
  selector: 'lib-field-date',
  templateUrl: './field-date.component.html',
  styleUrls: ['./field-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: FieldDateComponent,
    },
  ],
})
export class FieldDateComponent implements OnInit, ControlValueAccessor {
  @Input()
  public isOpen: boolean = false;
  @Input()
  public key: string = '';
  @Input()
  public dateFormatFormValue: string = 'YYYY-MM-dd';
  @Input()
  public dateFormatViewValue: string = 'YYYY-MM-dd';
  @Input()
  public view: ViewDatepicker = ViewDatepicker.DAYS;
  @Input()
  public minView!: ViewDatepicker;
  @Input()
  public range: boolean = false;
  @Input()
  public minDate: string = '';
  @Input()
  public maxDate: string = '';
  @Input()
  public startDate: string = '';

  @Output()
  public open: EventEmitter<any> = new EventEmitter();

  public currentValue: string = '';
  public date: any = '';

  public touched: boolean = false;
  public disabled: boolean = false;
  public onChange: any = (currentValue: any) => {};
  public onTouched: any = () => {};

  constructor(
    private datepipe: DatePipe
  ) { }

  public ngOnInit(): void {}

  public writeValue(currentValue: string): void {
    this.currentValue = currentValue;
  }

  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public toggleDatePicker(): void {
    this.isOpen = !this.isOpen;
    this.open.next(this.isOpen);
  }

  public onSelect(event: any): void {
    this.markAsTouched();
    if (!this.disabled) {
      this.date = event?.formattedDate;
      this.currentValue = event?.formattedDate;

      if (!this.range) {
        this.onChange(this.date);
        this.closeOverlay();
      } else {
        this.onChange([this.date[0], this.date[1]]);
      }
    }
  }

  private closeOverlay(): void {
    this.isOpen = false;
  }
}

import { FormArray, ValidationErrors } from '@angular/forms';

export function minSelectedCheckboxes(min: number = 1): ValidationErrors | null {
  return (formArray: FormArray) => {
    if (formArray.value.filter((item: any) => item.checked).length < min) {
      return { requiredMinSelectedCheckboxes: true };
    }

    return null;
  };
}

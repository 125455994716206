import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LogoResponsibleeComponent } from './logo-responsiblee/logo-responsiblee.component';

@NgModule({
  exports: [
    LogoResponsibleeComponent,
  ],
  declarations: [
    LogoResponsibleeComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class LogoResponsibleeModule { }

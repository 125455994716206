import { Component, Inject, OnInit } from '@angular/core';

import { ConfirmModalRef } from '../confirm-modal-ref';
import { CONFIRM_MODAL_DATA } from '../confirm-modal-tokens';

@Component({
  selector: 'lib-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  constructor(
    @Inject(CONFIRM_MODAL_DATA) public data: any,
    public confirmModalRef: ConfirmModalRef
  ) { }

  public ngOnInit(): void {
  }

  public cancel(): void {
    this.confirmModalRef.close();
  }

  public success(): void {
    this.data.success();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable()
export class RedirectToNewResetPasswordUrlPath implements CanActivate {
  constructor(
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    this.router.navigate(['/przypomnij-haslo/nowe-haslo'], {
      queryParams: {
        hash: route.queryParams['hash'],
      },
    });

    return false;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FieldCombineComponent } from './field-combine.component';

@NgModule({
  declarations: [FieldCombineComponent],
  exports: [FieldCombineComponent],
  imports: [
    CommonModule,
  ],
})
export class FieldCombineModule { }

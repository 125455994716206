import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AirDatepickerComponent } from './air-datepicker/air-datepicker.component';
import { FieldDateComponent } from './field-date/field-date.component';

@NgModule({
  declarations: [
    FieldDateComponent,
    AirDatepickerComponent,
  ],
  exports: [
    FieldDateComponent,
    AirDatepickerComponent,
  ],
  imports: [
    CommonModule,
    OverlayModule,
  ],
})
export class FieldDateModule { }

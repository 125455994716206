import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LottieModule } from 'ngx-lottie';

import { LoadingComponent } from './loading.component';

@NgModule({
  declarations: [
    LoadingComponent,
  ],
  exports: [
    LoadingComponent,
  ],
  imports: [
    CommonModule,
    LottieModule,
  ],
})
export class LoadingModule { }

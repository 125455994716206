import { Pipe, PipeTransform } from '@angular/core';

import { cloneDeep } from 'lodash';

@Pipe({
  name: 'filterObj',
  pure: false,
})
export class FilterObjPipe implements PipeTransform {
  public transform(items: any[], filter: any): any {
    if (!items || !filter) {
        return items;
    }

    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return cloneDeep(items)?.find(item => item?.id === Number(filter?.id));
  }
}

import { Component, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Destroyer } from '../destroyer/destroyer';
import { Observable, Subject } from 'rxjs';
import { itemsPerPageFun } from '../../../../responsiblee/src/app/app.const';

@Component({
  selector: 'lib-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent extends Destroyer {
  public itemsPerPage: FormControl = new FormControl(itemsPerPageFun());
  @Input()
  public totalCount$!: Observable<number>;
  @Input()
  public currentPage: number = 1;
  @Output()
  public changeItemsCount: Subject<number> = new Subject<number>();

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.listener();
  }

  public round(val: number): number {
    return Math.ceil(val);
  }

  private listener(): void {
    this.itemsPerPage.valueChanges.pipe().subscribe(() => {
      sessionStorage.setItem('itemsPerPage', this.itemsPerPage.value);
      this.changeItemsCount.next(this.itemsPerPage.value);
    })
  }
}
